/* eslint-disable object-shorthand */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import "./index.css";
import React from "react";
import { Tooltip, Chip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import MDBox from "components/Basics/MDBox";
import _lod from "lodash";

/**
 *
 * @param {*} param0
 * @returns
 */
export default function TagsList({
	list,
	labelField,
	surnameField,
	titleField,
	isForm,
	scroll,
	align = "center",
	selected,
	setSelected,
	isIntent,
	isUser,
	disabled,
	search
}) {
	/**
	 *
	 * @param {*} value
	 * @returns
	 */
	const toggleSelect = value => () => {
		if (selected) {
			// const currentIndex = selected.indexOf(value);
			const currentIndex = selected.findIndex(item => item.code === value.code);

			const newSelected = [...selected];

			if (currentIndex === -1) {
				let skill = { code: value.code, name: value.name, description: value.description };
				newSelected.push(skill);
			} else {
				newSelected.splice(currentIndex, 1);
			}
			setSelected(newSelected);
		}
	};
	let tagListScrollStyle = {
		display: "flex",
		justifyContent: "left",
		listStyle: "none",
		margin: 0
	};
	if (scroll) {
		tagListScrollStyle.flexWrap = "nowrap";
		tagListScrollStyle.overflow = "auto";
	} else {
		tagListScrollStyle.flexWrap = "wrap";
		tagListScrollStyle.justifyContent = align;
		tagListScrollStyle.width = "100%";
	}
	const compareByName = (a, b) => {
		const nameA = a[labelField].toUpperCase(); // Ignore la casse
		const nameB = b[labelField].toUpperCase();

		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0; // Les noms sont égaux
	};
	return (
		<span
			className={isForm ? "tagListForm" : "tagListScroll"}
			style={
				!isForm
					? tagListScrollStyle
					: {
							display: "flex",
							flexWrap: "wrap",
							width: "100%"
						}
			}
		>
			{list
				.filter(itemFilter => {
					if (search.trim().length >= 0) {
						let fullSearch = itemFilter[labelField];
						return fullSearch.toLowerCase().includes(search.toLowerCase());
					} else {
						return true;
					}
				})
				?.sort(compareByName)
				?.map(tag => {
					let label = "";
					if (isUser) {
						label = tag[labelField] + " " + tag[surnameField];
					} else {
						label = tag[labelField];
					}

					const title = tag[titleField];
					return (
						<Tooltip title={title} key={label}>
							<Chip
								style={{ textTransform: isUser ? "capitalize" : "initial" }}
								label={label}
								disabled={disabled}
								icon={
									isForm ? (
										selected.some(item => item.code === tag.code) ? (
											<DoneIcon />
										) : (
											<MDBox></MDBox>
										)
									) : (
										<MDBox></MDBox>
									)
								}
								color={
									isForm
										? selected.some(item => item.code === tag.code)
											? "info"
											: "default"
										: "default"
								}
								clickable={isForm}
								onClick={toggleSelect(tag)}
							/>
						</Tooltip>
					);
				})}
		</span>
	);
}

/**
 *
 * @param {*} param0
 * @returns
 */
export function TagsListProfil({
	list,
	labelField,
	surnameField,
	titleField,
	isForm,
	scroll,
	align = "center",
	selected,
	setSelected,
	isIntent,
	isUser,
	disabled,
	valueField,
	search
}) {
	/**
	 *
	 * @param {*} value
	 * @returns
	 */
	const toggleSelect = value => () => {
		if (selected) {
			// const currentIndex = selected.indexOf(value);
			const currentIndex = selected.findIndex(item => item[valueField] === value[valueField]);

			const newSelected = [...selected];

			if (currentIndex === -1) {
				let itemValue = value;
				newSelected.push(itemValue);
			} else {
				newSelected.splice(currentIndex, 1);
			}
			setSelected(newSelected);
		}
	};
	let tagListScrollStyle = {
		display: "flex",
		justifyContent: "left",
		listStyle: "none",
		margin: 0
	};
	if (scroll) {
		tagListScrollStyle.flexWrap = "nowrap";
		tagListScrollStyle.overflow = "auto";
	} else {
		tagListScrollStyle.flexWrap = "wrap";
		tagListScrollStyle.justifyContent = align;
		tagListScrollStyle.width = "100%";
	}
	const compareByName = (a, b) => {
		const nameA = a[labelField].toUpperCase(); // Ignore la casse
		const nameB = b[labelField].toUpperCase();

		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0; // Les noms sont égaux
	};
	return (
		<span
			className={isForm ? "tagListForm" : "tagListScroll"}
			style={
				!isForm
					? tagListScrollStyle
					: {
							display: "flex",
							flexWrap: "wrap",
							width: "100%"
						}
			}
		>
			{list
				.filter(itemFilter => {
					if (search.trim().length >= 0) {
						let fullSearch = itemFilter[labelField];
						return fullSearch.toLowerCase().includes(search.toLowerCase());
					} else {
						return true;
					}
				})
				?.sort(compareByName)
				?.map(tag => {
					let label = "";
					if (isUser) {
						label = tag[labelField] + " " + tag[surnameField];
					} else {
						label = tag[labelField];
					}

					const title = tag[titleField];
					return (
						<Tooltip title={title} key={label}>
							<Chip
								style={{ textTransform: isUser ? "capitalize" : "initial" }}
								label={label}
								disabled={disabled}
								icon={
									isForm ? (
										selected.some(item => item[valueField] === tag[valueField]) ? (
											<DoneIcon />
										) : (
											<MDBox></MDBox>
										)
									) : (
										<MDBox></MDBox>
									)
								}
								color={
									isForm
										? selected.some(item => item[valueField] === tag[valueField])
											? "info"
											: "default"
										: "default"
								}
								clickable={isForm}
								onClick={toggleSelect(tag)}
							/>
						</Tooltip>
					);
				})}
		</span>
	);
}
