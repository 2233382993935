import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import MDBox from "components/Basics/MDBox";

import {
	navbar,
	navbarContainer,
	navbarIconButton,
	navbarDesktopMenu,
	navbarMobileMenu
} from "components/Advanced/Navbars/DashboardNavbar/styles";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useState } from "react";
import CustomMenu from "components/Custom/CustomMenu";
import Notifications from "components/Custom/Notifications";

/*
 * Navbar for mobile
 */
const PhoneNavBar = ({
	handleMiniSidenav,
	iconsStyle,
	miniSidenav,
	light,
	handleConfiguratorOpen,
	setOpenMenu
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				width: "100%"
			}}
		>
			<div
				style={{
					flex: 1
				}}
			>
				{/*
				 * Open / reduce sidenav
				 */}
				<IconButton sx={navbarMobileMenu} onClick={handleMiniSidenav} size="small" disableRipple>
					<Icon fontSize="medium" sx={iconsStyle}>
						{miniSidenav ? "menu_open" : "menu"}
					</Icon>
				</IconButton>
			</div>

			<MDBox color={light ? "white" : "inherit"}>
				{/*
				 * Filters Menu
				 * On the bar = only the button to open the filter menu,
				 * add the filters in the custom menu
				 */}
				<IconButton size="medium" sx={navbarIconButton} onClick={() => setOpenMenu(true)}>
					<Icon sx={iconsStyle}>filter_alt</Icon>
				</IconButton>
				{/*
				 * Settings button
				 */}
				<IconButton size="medium" sx={navbarIconButton} onClick={handleConfiguratorOpen}>
					<Icon sx={iconsStyle}>settings</Icon>
				</IconButton>
			</MDBox>
		</div>
	);
};

/*
 * Navbar for desktop
 */
const DesktopNavBar = ({
	isMini,
	handleMiniSidenav,
	iconsStyle,
	miniSidenav,
	handleConfiguratorOpen,
	filters
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				width: "100%"
			}}
		>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					width: "100%"
				}}
			>
				<MDBox
					color="inherit"
					mb={{ xs: 1, md: 0 }}
					// sx={theme => navbarRow(theme, { isMini })}
					style={{
						display: "flex",
						alignItems: "stretch",
						width: "100%"
					}}
				>
					{/*
					 * Open / reduce sidenav
					 */}
					<IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
						<Icon fontSize="medium" sx={iconsStyle}>
							{miniSidenav ? "menu_open" : "menu"}
						</Icon>
					</IconButton>
					{/*
					 **********************
					 * Add components here
					 * for desktop navbar
					 **********************
					 */}
					{filters &&
						filters.map((filter, index) => {
							return (
								<MDBox mr={1} key={index} style={{ width: "100%" }}>
									{filter}
								</MDBox>
							);
						})}
				</MDBox>
			</div>
			{/*
			 * Settings button
			 */}
			<IconButton size="medium" sx={navbarIconButton} onClick={handleConfiguratorOpen}>
				<Icon sx={iconsStyle}>settings</Icon>
			</IconButton>
			<Notifications />
		</div>
	);
};

/*
 * Main component to display navbar
 */
function DashboardNavbar({ absolute = false, light = false, isMini = false, filters }) {
	const navbarType = "sticky";
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, openConfigurator, darkMode, isPhone } = controller;
	const [openMenu, setOpenMenu] = useState(false);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

	// Styles for the navbar icons
	const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
		color: () => (light || darkMode ? white.main : dark.main)
	});

	const phoneNavBar = isPhone;

	return (
		<>
			{/*
			 * This menu is used for phone navbar filters
			 */}
			<CustomMenu
				openMenu={openMenu}
				setOpenMenu={setOpenMenu}
				title="Filters"
				subTitle="Set filters"
				child={
					/*
					 **********************
					 * Add components here
					 * for mobile navbar
					 **********************
					 */
					filters
				}
			/>
			<AppBar
				position={absolute ? "absolute" : navbarType}
				color="inherit"
				sx={theme => navbar(theme, { absolute, light, darkMode })}
			>
				<Toolbar sx={theme => navbarContainer(theme)}>
					{/*
					 * Display correct navbar depending on screen size
					 */}
					{phoneNavBar ? (
						<PhoneNavBar
							handleMiniSidenav={handleMiniSidenav}
							iconsStyle={iconsStyle}
							miniSidenav={miniSidenav}
							light={light}
							handleConfiguratorOpen={handleConfiguratorOpen}
							setOpenMenu={setOpenMenu}
						/>
					) : (
						<DesktopNavBar
							isMini={isMini}
							handleMiniSidenav={handleMiniSidenav}
							iconsStyle={iconsStyle}
							miniSidenav={miniSidenav}
							handleConfiguratorOpen={handleConfiguratorOpen}
							filters={filters}
						/>
					)}
				</Toolbar>
			</AppBar>
		</>
	);
}

export default DashboardNavbar;
