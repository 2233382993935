/* eslint-disable import/order */
/* eslint-disable react/no-unescaped-entities */
import "./style.css";
import SettingsActions from "redux-react/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import OneValueChart from "pages/charts/oneValueChart";
import ProportionChart from "pages/charts/proportionChart";
import CrossTable from "pages/charts/crossTable";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import ChartsActions from "redux-react/actions/chartsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import {
	Box,
	Chip,
	FormControl,
	FormControlLabel,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	Switch
} from "@mui/material";
import i18n from "i18n";
import notFoundImage from "assets/images/error.svg";
import { selectFilteredSortedMenu } from "redux-react/reducers/profileReducer";
import C from "../constants";
import IconAccordion from "../components/IconAccordion";
import DictionaryButton from "components/Custom/DictionaryButton";

const DisplayChoiceStep = ({ chart, setChart, validStep, edit }) => {
	const [dictionary, setDictionary] = useState({});
	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const pages = useSelector(selectFilteredSortedMenu);

	const [previewChart, setPreviewChart] = useState(false);
	const [selectedPages, setSelectedPages] = useState([]);

	const [switchLabelController, setSwitchLabelController] = useState(
		Boolean(chart.display.datalabels)
	);
	// get previous request state
	const [previousRequest, setPreviousRequest] = useState(chart.request);

	const handleChange = (path, value) => {
		let copyChart = lod_.cloneDeep(chart);
		copyChart = lod_.set(copyChart, path, value);

		if (copyChart.display.title && copyChart.display.pages.length > 0 && !error) {
			validStep();
		} else {
			validStep(false);
		}

		setChart(copyChart);
	};

	const handleSwitchLabel = () => {
		setSwitchLabelController(!switchLabelController);
		handleChange("display.datalabels", !switchLabelController);
	};

	const handleSwitchLegend = () => {
		handleChange("options.legend", !chart.options.legend);
	};

	function getCorrectGraph(type) {
		try {
			switch (type) {
				case "oneValue":
					return (
						<OneValueChart
							chart={{
								...chart,
								loaded: previewChart.loaded
							}}
							data={previewChart.data}
							dictionary={dictionary}
						/>
					);
				case "proportion":
				case "oneDimension":
				case "list":
					return (
						<ProportionChart
							chart={{
								...chart,
								loaded: previewChart.loaded
							}}
							data={previewChart.data}
						/>
					);
				case "crossTable":
				case "timeSeries":
				case "multiCollection":
					return (
						<CrossTable
							chart={{
								...chart,
								loaded: previewChart.loaded
							}}
							data={previewChart.data}
						/>
					);
				case "paginedList":
					return (
						<DefaultDataTable
							list={{
								...chart,
								loaded: previewChart.loaded
							}}
							pagination={previewChart.pagination}
							canSearch
							table={previewChart.data}
							display={chart.request.attributesDisplay}
						/>
					);
				default:
					return null;
			}
		} catch (e) {
			return null;
		}
	}

	// Change preview chart
	const handleChangeSelect = event => {
		let { value } = event.target;
		setSelectedPages(typeof value === "string" ? value.split(",") : value);
	};

	// Load chart
	function loadChart() {
		const onSuccess = res => {
			if (res.charts.length === 0) {
				setError(true);
			} else {
				let resChart = res.charts[0];
				resChart.loaded = true;
				setPreviewChart(resChart);
			}
		};
		dispatch(ChartsActions.buildRawCharts([chart], [], [], onSuccess));
	}

	/**
	 * Load dictionary
	 * @param {string} codeDictionary - The code of the dictionary
	 * @returns {Promise} - The promise
	 */
	async function loadDictionary(codeDictionary) {
		return new Promise((resolve, reject) => {
			dispatch(
				SettingsActions.getDictionary(codeDictionary, res => {
					setDictionary({
						[codeDictionary]: res.dictionary
					});
					resolve();
				})
			);
		});
	}

	// Updates chart when request change
	useEffect(() => {
		if (!lod_.isEqual(previousRequest, chart.request)) {
			setPreviousRequest(chart.request);
			loadChart();
		}
	}, [chart.request]);

	// Updates selected pages
	useEffect(() => {
		handleChange(
			"display.pages",
			selectedPages.map(page => page.replace("/", ""))
		);
	}, [selectedPages]);

	// Default use effect
	useEffect(() => {
		// Load dictionary
		if (chart.request?.collection) {
			loadDictionary(chart.request?.collection);
		}

		if (edit) {
			setSelectedPages(chart.display.pages.map(page => "/" + page));
			setSwitchLabelController(Boolean(chart.display.datalabels));
		} else {
			let basicDisplay = {
				...chart.display,
				datalabels: false,
				valueAfterPercentage: false,
				label: "",
				title: "",
				description: "",
				icon: {
					component: "equalizer",
					color: "info"
				},
				pages: []
			};

			if (chart.type === "proportion") {
				basicDisplay.label = "%";
				basicDisplay.valueAfterPercentage = true;
			}

			handleChange("display", basicDisplay);
		}

		loadChart();
	}, []);

	if (lod_.isEmpty(dictionary)) return null;

	return (
		<MDBox
			display="flex"
			style={{
				height: "100%"
			}}
		>
			{/* Left tab */}
			<MDBox flex="3" className="displayLeftTab">
				{/* <MDTypography variant="h3">{i18n.t("SETTINGS.CHARTS.NEW.chartPreview")}</MDTypography> */}
				<MDBox className="dialogContentChartEditor fullHeight" p={3} borderRadius="md">
					<MDBox flex="1" mt={4}>
						{previewChart && getCorrectGraph(chart.type)}
					</MDBox>
					{error && (
						<MDBox
							mt={2}
							display="flex"
							justifyContent="center"
							flexDirection="column"
							alignItems="center"
						>
							<MDBox mt={-1} component="img" src={notFoundImage} alt="not found" width="60%"></MDBox>
							<MDTypography variant="caption" color="error" mt={-1} fontSize="medium">
								{i18n.t("SETTINGS.CHARTS.NEW.cannotPreview")}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
			{/* Right Tab */}
			<MDBox
				flex="2"
				ml={1}
				style={{
					overflow: "auto"
				}}
			>
				<MDBox mt={2}>
					<MDTypography variant="h6">{i18n.t("SETTINGS.CHARTS.NEW.informations")}</MDTypography>
					{/* Titre */}
					<MDBox mt={2}>
						<MDInput
							className="dialogInput"
							label={
								<span>
									{i18n.t("SETTINGS.CHARTS.NEW.chartTitle")}
									<span className="mandatoryField">*</span>
								</span>
							}
							value={chart.display.title || ""}
							onChange={e => handleChange("display.title", e.target.value)}
						/>
					</MDBox>
					{/* Description */}
					{chart.type !== "oneValue" && (
						<MDBox mt={2}>
							<MDInput
								className="dialogInput"
								label={i18n.t("SETTINGS.CHARTS.NEW.chartDescription")}
								value={chart.display.description || ""}
								onChange={e => handleChange("display.description", e.target.value)}
							/>
						</MDBox>
					)}
					{/* Icon */}
					<MDBox mt={2}>
						<MDTypography variant="h6">{i18n.t("SETTINGS.CHARTS.NEW.icon")}</MDTypography>
					</MDBox>
					<MDBox display="flex" alignItems="flex-start " flexDirection="column">
						<MDBox mt={1}>
							{/* Color choice */}
							<IconAccordion
								title={
									<MDBox display="flex" alignItems="center">
										<MDBox
											mr={1}
											bgColor={chart.display.icon.color}
											variant="gradient"
											coloredShadow={chart.display.icon.color}
											borderRadius="xl"
											className="previewColorPickerContainer"
										></MDBox>
										<MDTypography variant="body1" fontSize="small">
											{i18n.t("SETTINGS.CHARTS.NEW.iconAvailableColors")}
										</MDTypography>
									</MDBox>
								}
								content={
									<MDBox display="flex" flexWrap="wrap">
										{C.ICONS_COLORS.map((color, index) => {
											return (
												<MDBox
													key={index}
													mr={1}
													bgColor={color}
													variant="gradient"
													coloredShadow={color}
													borderRadius="xl"
													className="colorPickerContainer"
													onClick={() => {
														handleChange("display.icon.color", color);
													}}
												>
													<Icon fontSize="small">{chart.display.icon.component}</Icon>
												</MDBox>
											);
										})}
									</MDBox>
								}
							/>
							{/* Icon choice */}
							<IconAccordion
								title={
									<MDBox display="flex" alignItems="center">
										<MDBox mr={1} display="flex" alignItems="center">
											<Icon fontSize="medium">{chart.display.icon.component}</Icon>
										</MDBox>
										<MDTypography variant="body1" fontSize="small">
											{i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
										</MDTypography>
									</MDBox>
								}
								content={C.ICONS_LIST.map((icon, index) => {
									return (
										<IconButton
											key={index}
											onClick={() => {
												let newIcon = {
													component: icon,
													color: chart.display.icon?.color || "info"
												};
												handleChange("display.icon", newIcon);
											}}
										>
											<Icon
												color={chart.display.icon.component === icon ? "info" : ""}
												fontSize="large"
											>
												{icon}
											</Icon>
										</IconButton>
									);
								})}
							/>
						</MDBox>
					</MDBox>
					{/* Pages */}
					<MDBox mt={2}>
						<MDTypography variant="h6">{i18n.t("SETTINGS.CHARTS.NEW.display")}</MDTypography>
					</MDBox>
					<MDBox mt={3}>
						<FormControl fullWidth>
							<InputLabel id="select-pages-label">
								<span>
									{i18n.t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
									<span className="mandatoryField">*</span>
								</span>
							</InputLabel>
							<Select
								labelId="select-pages-label"
								id="select-pages"
								multiple
								value={selectedPages}
								onChange={handleChangeSelect}
								input={
									<OutlinedInput
										id="select-pages-chip"
										label={
											<span>
												{i18n.t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
												<span className="mandatoryField">*</span>
											</span>
										}
									/>
								}
								renderValue={selected => (
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
										{selected.map((page, index) => {
											let goodPage = pages.find(p => p.route === page);
											if (goodPage) {
												return <Chip key={index} label={goodPage.name} />;
											} else {
												return null;
											}
										})}
									</Box>
								)}
							>
								{pages.map((page, index) => (
									<MenuItem key={index} value={page.route}>
										{page.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</MDBox>
					{/* Attributes display */}
					{["sortedList"].includes(chart.display.subType) && !chart.request.group && (
						<MDBox display="flex" mt={2}>
							<MDBox
								borderRadius="lg"
								display="flex"
								alignItems="center"
								bgColor="light"
								sx={{ width: "100%", mr: 1, p: 0.5 }}
								style={{
									border: "1px solid #d2d6da"
								}}
							>
								{!chart.request.attributesDisplay.length ? (
									<MDBox ml={1}>
										<MDTypography fontSize="small">Aucun champ à afficher</MDTypography>
									</MDBox>
								) : (
									chart.request.attributesDisplay.map((attribute, index) => {
										let path = attribute.replaceAll(".", ".items.");
										let dicObject = lod_.get(dictionary[chart.request.collection].items, path);
										return (
											<Chip
												key={index}
												sx={{ mr: 0.5 }}
												label={
													<MDBox display="flex" alignItems="center">
														<MDBox>{dicObject.label.fr}</MDBox>
														<IconButton
															size="small"
															onClick={() => {
																let copyAttributesDisplay = lod_.cloneDeep(
																	chart.request.attributesDisplay
																);
																copyAttributesDisplay.splice(index, 1);
																handleChange("request.attributesDisplay", copyAttributesDisplay);
															}}
														>
															<Icon>close</Icon>
														</IconButton>
													</MDBox>
												}
											/>
										);
									})
								)}
							</MDBox>
							<DictionaryButton
								sx={{ m: 0 }}
								dictionary={dictionary}
								handleClick={value => {
									value = value.split(".");
									value.shift();
									value = value.join(".");
									handleChange("request.attributesDisplay", [
										...chart.request.attributesDisplay,
										value
									]);
								}}
							/>
						</MDBox>
					)}
					<MDBox mt={2}>
						<MDTypography variant="h6">{i18n.t("SETTINGS.CHARTS.NEW.options")}</MDTypography>
					</MDBox>
					{/* ################################################ */}
					{/*	PART FOR : ONEVALUE							     */}
					{/* ################################################ */}
					{["oneValue"].includes(chart.type) && chart.display.subType !== "ranking" && (
						<>
							{/* Previous period */}
							<MDBox
								mt={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								className="customSwitchContainer"
								onClick={() => handleChange("request.previousPeriod", !chart.request.previousPeriod)}
							>
								<span>Comparaison de periode</span>
								<MDBox>
									<Switch checked={chart.request.previousPeriod} />
								</MDBox>
							</MDBox>
						</>
					)}
					{/* ################################################ */}
					{/*	PART FOR : ONEVALUE / ONEDIMENSION / PROPORTION  */}
					{/* ################################################ */}
					{["oneValue", "oneDimension", "proportion"].includes(chart.type) && (
						<>
							{/* Limit */}
							{chart.type !== "oneValue" && (
								<MDBox mt={2}>
									<MDInput
										value={chart.request.limit}
										type="number"
										className="dialogInput"
										label={i18n.t("SETTINGS.CHARTS.NEW.displayLimit")}
										InputProps={{ inputProps: { min: 1 } }}
										onChange={e => {
											if (e.target.value < 1) e.target.value = null;
											handleChange("request.limit", e.target.value);
										}}
									/>
								</MDBox>
							)}
							{/* Labels */}
							<MDBox mt={2}>
								<MDInput
									value={chart.display.label || ""}
									className="dialogInput"
									label={i18n.t("SETTINGS.CHARTS.NEW.displayLabel")}
									onChange={e => handleChange("display.label", e.target.value)}
								/>
							</MDBox>
							{/* Etiquettes */}
							{chart.type !== "oneValue" && (
								<MDBox
									mt={2}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									className="customSwitchContainer"
									onClick={() => handleSwitchLabel()}
								>
									<span>{i18n.t("SETTINGS.CHARTS.NEW.displayDatalabels")}</span>
									<MDBox>
										<Switch checked={chart.display.datalabels ?? false} />
									</MDBox>
								</MDBox>
							)}
							{/* Display values for % */}
							{chart.type === "proportion" && (
								<MDBox
									mt={2}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									className="customSwitchContainer"
									onClick={() => {
										handleChange(
											"display.valueAfterPercentage",
											!chart.display.valueAfterPercentage
										);
									}}
								>
									<span>{i18n.t("SETTINGS.CHARTS.NEW.displayValueAfterPercentage")}</span>
									<MDBox>
										<Switch checked={chart.display.valueAfterPercentage ?? false} />
									</MDBox>
								</MDBox>
							)}
						</>
					)}
					{["sortedList"].includes(chart.display.subType) && (
						<MDBox
							mt={2}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							className="customSwitchContainer"
						>
							<span>Limite</span>
							<MDBox>
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={chart.request.limit || "1"}
										onChange={e => handleChange("request.limit", e.target.value)}
									>
										<FormControlLabel value="1" control={<Radio />} label="1" />
										<FormControlLabel value="3" control={<Radio />} label="3" />
										<FormControlLabel value="5" control={<Radio />} label="5" />
										<FormControlLabel value="10" control={<Radio />} label="10" />
										<FormControlLabel value="20" control={<Radio />} label="20" />
										<FormControlLabel value="50" control={<Radio />} label="50" />
										<FormControlLabel value="null" control={<Radio />} label="Tous" />
									</RadioGroup>
								</FormControl>
							</MDBox>
						</MDBox>
					)}
					{/* Sort  */}
					{["proportion", "oneDimension"].includes(chart.type) && (
						<MDBox
							mt={2}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							className="customSwitchContainer"
						>
							<span>Tri</span>
							<MDBox>
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={chart.request.sort || "none"}
										onChange={e => handleChange("request.sort", e.target.value)}
									>
										<FormControlLabel value="none" control={<Radio />} label="Aucun" />
										<FormControlLabel value="asc" control={<Radio />} label="Ascendant" />
										<FormControlLabel value="desc" control={<Radio />} label="Descendant" />
									</RadioGroup>
								</FormControl>
							</MDBox>
						</MDBox>
					)}
					{/* Sort for sortedlist */}
					{["sortedList", "ranking"].includes(chart.display.subType) && (
						<MDBox
							mt={2}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							className="customSwitchContainer"
						>
							<span>Tri</span>
							<MDBox>
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={chart.request.sort || "desc"}
										onChange={e => handleChange("request.sort", e.target.value)}
									>
										<FormControlLabel value="asc" control={<Radio />} label="Ascendant" />
										<FormControlLabel value="desc" control={<Radio />} label="Descendant" />
									</RadioGroup>
								</FormControl>
							</MDBox>
						</MDBox>
					)}
					{/* ################################################ */}
					{/*	PART FOR : TIMESERIES / CrossTabel (no table)	 */}
					{/* ################################################ */}
					{["timeSeries", "crossTable"].includes(chart.type) &&
						!["table"].includes(chart.display.subType) && (
							<>
								{/* Legende */}
								<MDBox
									mt={2}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									className="customSwitchContainer"
									onClick={() => handleSwitchLegend()}
								>
									<span>Afficher la légende</span>
									<MDBox>
										<Switch checked={chart.options.legend || true} />
									</MDBox>
								</MDBox>
							</>
						)}
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default DisplayChoiceStep;
