import lod_ from "lodash";
import { FormControlLabel, Icon, IconButton, Radio, RadioGroup } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import InputString from "components/Custom/LittleForm/Inputs/InputString";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import { selectUser } from "redux-react/reducers/userReducers";

/**
 * Left pannel to filter the shops
 * @param {*} param0
 * @returns
 */
const LeftPannel = ({ dictionary, filters, setFilters }) => {
	const dispatch = useDispatch();

	const user = useSelector(selectUser);

	const [listOfApis, setListOfApis] = useState([]);
	const [selectedShopsFilter, setSelectedShopsFilter] = useState([]);

	/**
	 * Change a filter value
	 * @param {string} attribute - Attribute to filter
	 * @param {string} type - Type of the filter
	 * @param {any} value - Value of the filter
	 */
	const changeFilter = (attribute, type, value) => {
		setFilters(prev => {
			const clone = lod_.cloneDeep(prev);
			const filter = clone[attribute];
			if (filter) {
				if (filter.value === value) {
					delete clone[attribute];
					return clone;
				} else {
					return {
						...clone,
						[attribute]: {
							name: attribute,
							value,
							type
						}
					};
				}
			} else {
				return {
					...clone,
					[attribute]: {
						name: attribute,
						value,
						type
					}
				};
			}
		});
	};

	useEffect(() => {
		const onSuccessApis = res => {
			setListOfApis(res?.apisList || []);
		};

		dispatch(FormActions.getListApis(user.assistantID, onSuccessApis));
	}, []);

	return (
		<MDBox
			sx={{
				position: "sticky",
				top: 0
			}}
			p={0.5}
			mr={2}
			flex="1"
		>
			<MDTypography variant="h6" component="h6" sx={{ mb: 1 }}>
				Filtrer les fiches Google
			</MDTypography>

			{/* Active */}
			<MDTypography variant="body2" fontSize="small" fontWeight="bold" sx={{ mt: 1, opacity: 0.8 }}>
				Active
			</MDTypography>
			<RadioGroup row name="row-radio-buttons-group">
				<FormControlLabel
					value="yes"
					control={
						<Radio
							checked={filters?.active?.value === true}
							onClick={e => changeFilter("active", "boolean", true)}
						/>
					}
					label="Oui"
				/>
				<FormControlLabel
					value="no"
					control={
						<Radio
							checked={filters?.active?.value === false}
							onClick={e => changeFilter("active", "boolean", false)}
						/>
					}
					label="Non"
				/>
			</RadioGroup>

			{/* Configured */}
			<MDTypography variant="body2" fontSize="small" fontWeight="bold" sx={{ mt: 1, opacity: 0.8 }}>
				Opérationnelle
			</MDTypography>
			<RadioGroup row name="row-radio-buttons-group">
				<FormControlLabel
					value="yes"
					control={
						<Radio
							checked={filters?.configured?.value === true}
							onClick={e => changeFilter("configured", "boolean", true)}
						/>
					}
					label="Oui"
				/>
				<FormControlLabel
					value="no"
					control={
						<Radio
							checked={filters?.configured?.value === false}
							onClick={e => changeFilter("configured", "boolean", false)}
						/>
					}
					label="Non"
				/>
			</RadioGroup>

			{/* Brand */}
			<MDBox
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
				mb={1}
			>
				<MDTypography
					variant="body2"
					fontSize="small"
					fontWeight="bold"
					sx={{ mt: 1, opacity: 0.8 }}
				>
					Marque
				</MDTypography>
				<IconButton
					size="small"
					onClick={() => {
						setFilters(prev => {
							let clone = lod_.cloneDeep(prev);
							delete clone.brand;
							return clone;
						});
					}}
				>
					<Icon>close</Icon>
				</IconButton>
			</MDBox>
			<InputString
				label="Marque"
				disabled={false}
				value={filters?.brand?.value || ""}
				onChange={e => {
					changeFilter("brand", "string", e);
				}}
				item={dictionary.brand}
				listOfApis={listOfApis}
				error=""
				InputProps={{}}
			/>

			{/* Shop */}
			<MDBox
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
				mb={1}
			>
				<MDTypography
					variant="body2"
					fontSize="small"
					fontWeight="bold"
					sx={{ mt: 1, opacity: 0.8 }}
				>
					Magasins référents
				</MDTypography>
			</MDBox>
			<InputString
				label="Magasins référents"
				disabled={false}
				value={selectedShopsFilter}
				onChange={values => {
					setSelectedShopsFilter(values);
					if (lod_.isEmpty(values)) {
						setFilters(prev => {
							const clone = lod_.cloneDeep(prev);
							delete clone?.competitors;
							return clone;
						});
					} else {
						setFilters(prev => {
							const clone = lod_.cloneDeep(prev);
							return {
								...clone,
								competitors: { $in: values }
							};
						});
					}
				}}
				item={dictionary.competitors}
				listOfApis={listOfApis}
				error=""
				InputProps={{}}
			/>
		</MDBox>
	);
};

export default LeftPannel;
