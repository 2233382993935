/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * #######################################################@
 *
 * Custom menu component
 *
 * Create a menu
 *
 * #######################################################@
 */
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import ConfiguratorRoot from "components/Advanced/Configurator/ConfiguratorRoot";
import { useMaterialUIController } from "context";

function CustomMenu({ title, subTitle, openMenu, setOpenMenu, child }) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const handleCloseMenu = () => setOpenMenu(false);

	return (
		<>
			{openMenu && (
				<div
					style={{
						height: "100%",
						width: "100%",
						backgroundColor: "#0000004a",
						position: "fixed",
						top: 0,
						left: 0,
						zIndex: 1200
					}}
					onClick={handleCloseMenu}
				></div>
			)}

			<ConfiguratorRoot variant="permanent" ownerState={{ openMenu }}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="baseline"
					pt={4}
					pb={0.5}
					px={3}
				>
					<MDBox>
						<MDTypography variant="h5">{title}</MDTypography>
						<MDTypography variant="body2" color="text">
							{subTitle}
						</MDTypography>
					</MDBox>

					<Icon
						sx={({ typography: { size }, palette: { dark, white } }) => ({
							fontSize: `${size.lg} !important`,
							color: darkMode ? white.main : dark.main,
							stroke: "currentColor",
							strokeWidth: "2px",
							cursor: "pointer",
							transform: "translateY(5px)"
						})}
						onClick={handleCloseMenu}
					>
						close
					</Icon>
				</MDBox>

				<Divider />

				{child?.map((el, index) => {
					return <div key={index}>{el}</div>;
				})}
			</ConfiguratorRoot>
		</>
	);
}

export default CustomMenu;
