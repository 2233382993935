import { Divider, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import MDBox from "components/Basics/MDBox";
import { useRef, useState } from "react";
import DownloadList from "./DownloadList";

const ListOptions = ({ options, chart }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openDownloadList, setOpenDownloadList] = useState(false);
	const buttonRef = useRef(null);

	let download = options.download ?? { active: true };
	let edit = options.edit ?? { active: false };
	let resize = options.resize ?? { active: false };

	const NO_ACTIONS_AVAILABLE = !download.active && !edit.active && !resize.active;

	const handleResize = size => {
		resize.action(size);
		setAnchorEl(null);
		setTimeout(() => {
			setAnchorEl(buttonRef?.current);
		}, 300);
	};

	const renderMenu = () => {
		return (
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
				{/* Actions */}
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">Actions</MDTypography>
				</MenuItem>

				{NO_ACTIONS_AVAILABLE && (
					<MenuItem disabled style={{ opacity: 0.5 }}>
						Aucune action disponible
					</MenuItem>
				)}

				{edit.active && (
					<MenuItem
						onClick={() => {
							// setOpenDownloadList(true);
							edit.action();
							setAnchorEl(null);
						}}
					>
						<Icon>edit</Icon>&nbsp;Editer
					</MenuItem>
				)}

				{resize.active && chart && (
					<>
						<MenuItem disabled style={{ opacity: 1 }}>
							<MDTypography variant="caption">Redimensionner</MDTypography>
						</MenuItem>
						<MDBox display="flex" flexDirection="row">
							<MenuItem selected={chart.display.width === 2} onClick={() => handleResize(2)}>
								1/3
							</MenuItem>
							<MenuItem selected={chart.display.width === 3} onClick={() => handleResize(3)}>
								1/2
							</MenuItem>
							<MenuItem selected={chart.display.width === 6} onClick={() => handleResize(6)}>
								1/1
							</MenuItem>
						</MDBox>
					</>
				)}
				{download.active && (
					<MenuItem
						onClick={() => {
							setOpenDownloadList(true);
							setAnchorEl(null);
						}}
					>
						<Icon>download</Icon>&nbsp;Télécharger
					</MenuItem>
				)}
			</Menu>
		);
	};

	return (
		<>
			<Tooltip placement="top" title="Plus d'actions">
				<IconButton
					ref={buttonRef}
					onClick={e => {
						setAnchorEl(e.currentTarget);
					}}
				>
					<Icon>more_vert</Icon>
				</IconButton>
			</Tooltip>
			{renderMenu()}
			{/* Download list */}
			{openDownloadList && chart && (
				<DownloadList
					open={openDownloadList}
					onClose={() => setOpenDownloadList(false)}
					data={chart?.data}
					title={chart?.display?.title}
					chart={chart}
				/>
			)}
		</>
	);
};

export default ListOptions;
