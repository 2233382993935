/* eslint-disable no-extra-boolean-cast */
import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";

const InputBoolean = ({ label, value, onChange, disabled, error, InputProps = {}, ...rest }) => {
	return (
		<MDBox
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={e => {
				if (!disabled) {
					onChange(!Boolean(value));
				}
			}}
			{...InputProps}
			bgColor={disabled ? "light" : "white"}
		>
			<span>{label}</span>
			<MDBox>
				<Switch disabled={disabled} checked={Boolean(value)} />
			</MDBox>
		</MDBox>
	);
};
export default InputBoolean;
