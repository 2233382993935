/* eslint-disable import/no-import-module-exports */
import oneValue from "assets/images/charts/oneValue.png";
import oneDimension from "assets/images/charts/oneDimension.png";
import proportion from "assets/images/charts/proportion.png";
import crossTable from "assets/images/charts/crossTable.png";
import timeSeries from "assets/images/charts/timeSeries.png";

import card from "assets/images/charts/subType/card.png";
import ranking from "assets/images/charts/subType/ranking.png";
import donut from "assets/images/charts/subType/donut.png";
import pie from "assets/images/charts/subType/pie.png";
import verticalBar from "assets/images/charts/subType/verticalBar.png";
import horizontalBar from "assets/images/charts/subType/horizontalBar.png";
import table from "assets/images/charts/subType/table.png";
import crossTableST from "assets/images/charts/subType/crossTable.png";
import timeSeriesST from "assets/images/charts/subType/timeSeries.png";
import line from "assets/images/charts/subType/line.png";
import stackedBar from "assets/images/charts/subType/stackedBar.png";
/**
 * Charts' types
 */
const CHARTS_TYPE = [
	{
		image: oneValue,
		title: "SETTINGS.CHARTS.TYPE.oneValue.title",
		type: "oneValue",
		description: "SETTINGS.CHARTS.TYPE.oneValue.description"
	},
	{
		image: oneDimension,
		title: "SETTINGS.CHARTS.TYPE.oneDimension.title",
		type: "oneDimension",
		description: "SETTINGS.CHARTS.TYPE.oneDimension.description"
	},
	{
		image: proportion,
		title: "SETTINGS.CHARTS.TYPE.proportion.title",
		type: "proportion",
		description: "SETTINGS.CHARTS.TYPE.proportion.description"
	},
	{
		image: crossTable,
		title: "SETTINGS.CHARTS.TYPE.crossTable.title",
		type: "crossTable",
		description: "SETTINGS.CHARTS.TYPE.crossTable.description"
	},
	{
		image: line,
		title: "SETTINGS.CHARTS.TYPE.timeSeries.title",
		type: "timeSeries",
		description: "SETTINGS.CHARTS.TYPE.timeSeries.description"
	},
	{
		image: table,
		title: "SETTINGS.CHARTS.TYPE.listes.title",
		type: "list",
		description: "SETTINGS.CHARTS.TYPE.listes.description"
	}
];
/**
 * Charts' subtypes
 */
const CHARTS_SUBTYPE = {
	list: [
		{
			image: table,
			code: "sortedList",
			title: "SETTINGS.CHARTS.TYPE.listes.subType.sortedList.title",
			description: "SETTINGS.CHARTS.TYPE.listes.subType.sortedList.description"
		}
	],
	oneValue: [
		{
			image: card,
			code: "card",
			title: "SETTINGS.CHARTS.TYPE.oneValue.subType.card.title",
			description: "SETTINGS.CHARTS.TYPE.oneValue.subType.card.description"
		},
		{
			image: ranking,
			code: "ranking",
			title: "SETTINGS.CHARTS.TYPE.oneValue.subType.ranking.title",
			description: "SETTINGS.CHARTS.TYPE.oneValue.subType.ranking.description"
		}
	],
	oneDimension: [
		{
			image: verticalBar,
			code: "verticalBar",
			title: "SETTINGS.CHARTS.TYPE.oneDimension.subType.verticalBar.title",
			description: "SETTINGS.CHARTS.TYPE.oneDimension.subType.verticalBar.description"
		},
		{
			image: horizontalBar,
			code: "horizontalBar",
			title: "SETTINGS.CHARTS.TYPE.oneDimension.subType.horizontalBar.title",
			description: "SETTINGS.CHARTS.TYPE.oneDimension.subType.horizontalBar.description"
		},
		{
			image: donut,
			code: "donut",
			title: "SETTINGS.CHARTS.TYPE.oneDimension.subType.donut.title",
			description: "SETTINGS.CHARTS.TYPE.oneDimension.subType.donut.description"
		},
		{
			image: pie,
			code: "pie",
			title: "SETTINGS.CHARTS.TYPE.oneDimension.subType.pie.title",
			description: "SETTINGS.CHARTS.TYPE.oneDimension.subType.pie.description"
		},
		{
			image: table,
			code: "table",
			title: "SETTINGS.CHARTS.TYPE.oneDimension.subType.table.title",
			description: "SETTINGS.CHARTS.TYPE.oneDimension.subType.table.description"
		}
	],
	proportion: [
		{
			image: verticalBar,
			code: "verticalBar",
			title: "SETTINGS.CHARTS.TYPE.proportion.subType.verticalBar.title",
			description: "SETTINGS.CHARTS.TYPE.proportion.subType.verticalBar.description"
		},
		{
			image: horizontalBar,
			code: "horizontalBar",
			title: "SETTINGS.CHARTS.TYPE.proportion.subType.horizontalBar.title",
			description: "SETTINGS.CHARTS.TYPE.proportion.subType.horizontalBar.description"
		},
		{
			image: donut,
			code: "donut",
			title: "SETTINGS.CHARTS.TYPE.proportion.subType.donut.title",
			description: "SETTINGS.CHARTS.TYPE.proportion.subType.donut.description"
		},
		{
			image: pie,
			code: "pie",
			title: "SETTINGS.CHARTS.TYPE.proportion.subType.pie.title",
			description: "SETTINGS.CHARTS.TYPE.proportion.subType.pie.description"
		},
		{
			image: table,
			code: "table",
			title: "SETTINGS.CHARTS.TYPE.proportion.subType.table.title",
			description: "SETTINGS.CHARTS.TYPE.proportion.subType.table.description"
		}
	],
	crossTable: [
		{
			image: crossTableST,
			code: "table",
			title: "SETTINGS.CHARTS.TYPE.crossTable.subType.table.title",
			description: "SETTINGS.CHARTS.TYPE.crossTable.subType.table.description"
		},
		{
			image: stackedBar,
			code: "stackedBar",
			title: "SETTINGS.CHARTS.TYPE.timeSeries.subType.stackedBar.title",
			description: "SETTINGS.CHARTS.TYPE.timeSeries.subType.stackedBar.description"
		}
	],
	timeSeries: [
		{
			image: timeSeriesST,
			code: "table",
			title: "SETTINGS.CHARTS.TYPE.timeSeries.subType.table.title",
			description: "SETTINGS.CHARTS.TYPE.timeSeries.subType.table.description"
		},
		{
			image: line,
			code: "line",
			title: "SETTINGS.CHARTS.TYPE.timeSeries.subType.line.title",
			description: "SETTINGS.CHARTS.TYPE.timeSeries.subType.line.description"
		},
		{
			image: stackedBar,
			code: "stackedBar",
			title: "SETTINGS.CHARTS.TYPE.timeSeries.subType.stackedBar.title",
			description: "SETTINGS.CHARTS.TYPE.timeSeries.subType.stackedBar.description"
		}
	]
};

const COMP_DEFAULT = [
	{
		code: "$in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.in"
	},
	{
		code: "$nin",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.nin"
	}
];
/**
 * All possibles methods
 */
const COMP_STRING = [
	...COMP_DEFAULT,
	{
		code: "$eq",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},
	{
		code: "$ne",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	}
];

const COMP_NUMBER = [
	...COMP_DEFAULT,
	{
		code: "$eq",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},
	{
		code: "$ne",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	},
	{
		code: "$gt",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt"
	},
	{
		code: "$gte",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gte"
	},
	{
		code: "$lt",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt"
	},
	{
		code: "$lte",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lte"
	}
];

const COMP_CODE = [
	...COMP_DEFAULT,
	{
		code: "$eq",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},
	{
		code: "$ne",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	}
];

const COMP_DATE = [
	{
		code: "$gt",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt"
	},
	{
		code: "$lt",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt"
	}
];
/**
 * All possibles computing methods
 */
const COMPUTE_METHOD = [
	{
		code: "SUM",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.sum",
		description: "Permet de faire la somme des valeurs",
		icon: "functions",
		types: ["number"]
	},
	{
		code: "AVG",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.avg",
		description: "Permet de faire la moyenne des valeurs",
		icon: "align_horizontal_center",
		types: ["number"]
	},
	{
		code: "COUNT",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.count",
		description: "Permet de dénombrer le nombre d'enregistrements",
		icon: "format_list_bulleted",
		types: []
	},
	{
		code: "MAX",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.max",
		description: "Permet de trouver la valeur maximale",
		icon: "expand_less",
		types: ["number", "date", "datetime"]
	},
	{
		code: "MIN",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.min",
		description: "Permet de trouver la valeur minimale",
		icon: "expand_more",
		types: ["number", "date", "datetime"]
	},
	{
		code: "DATE_MIN",
		label: "La plus ancienne",
		description: "Permet de trouver la date la plus ancienne",
		icon: "restore",
		types: ["date", "datetime"]
	},
	{
		code: "DATE_MAX",
		label: "La plus récente",
		description: "Permet de trouver la date la plus récente",
		icon: "update",
		types: ["date", "datetime"]
	}
];
/**
 * All possibles binning formats
 */
const MONGODB_BINNING_FORMAT = [
	{
		code: "year",
		label: "SETTINGS.CHARTS.DATE.year"
	},
	{
		code: "month",
		label: "SETTINGS.CHARTS.DATE.month"
	},
	{
		code: "dayOfYear",
		label: "SETTINGS.CHARTS.DATE.dayOfYear"
	},
	{
		code: "dayOfMonth",
		label: "SETTINGS.CHARTS.DATE.dayOfMonth"
	},
	{
		code: "dayOfWeek",
		label: "SETTINGS.CHARTS.DATE.dayOfWeek"
	},
	{
		code: "hour",
		label: "SETTINGS.CHARTS.DATE.hour"
	},
	{
		code: "minute",
		label: "SETTINGS.CHARTS.DATE.minute"
	},
	{
		code: "second",
		label: "SETTINGS.CHARTS.DATE.second"
	},
	{
		code: "week",
		label: "SETTINGS.CHARTS.DATE.week"
	}
];

const ICONS_LIST = [
	"show_chart",
	"pie_chart",
	"bar_chart",
	"bubble_chart",
	"multiline_chart",
	"scatter_plot",
	"line_style",
	"line_weight",
	"insert_chart_outlined",
	"bar_chart_boxed",
	"stacked_line_chart",
	"stacked_bar_chart",
	"area_chart",
	"drag_handle",
	"format_line_spacing",
	"linear_scale",
	"horizontal_distribute",
	"vertical_align_bottom",
	"vertical_align_center",
	"vertical_align_top",
	"horizontal_rule",
	"table_chart",
	"insert_chart",
	"grid_on",
	"grid_off",
	"message",
	"forum",
	"email",
	"drafts",
	"textsms",
	"comment",
	"question_answer",
	"chat",
	"chat_bubble",
	"chat_bubble_outline",
	"alternate_email",
	"voice_chat",
	"video_call",
	"mark_chat_read",
	"mark_chat_unread"
];

const PAGE_ICONS_LIST = [
	"moving",
	"donut_large",
	"analytics",
	"insert_chart_outlined",
	"bar_chart",
	"scatter_plot",
	"pie_chart",
	"show_chart",
	"multiline_chart",
	"assessment",
	"compare_arrows",
	"trending_up",
	"insights",
	"table_chart",
	"line_style",
	"line_weight",
	"functions",
	"laptop_mac",
	"highlight_alt",
	"query_stats",
	"stacked_line_chart",
	"area_chart",
	"data_usage",
	"drag_indicator",
	"horizontal_distribute",
	"signal_cellular_alt",
	"timeline",
	"trending_down",
	"wave"
];

const ICONS_COLORS = ["info", "success", "warning", "error", "primary", "secondary"];

export default {
	CHARTS_TYPE,
	CHARTS_SUBTYPE,
	COMP_STRING,
	COMP_NUMBER,
	COMP_CODE,
	COMP_DATE,
	COMPUTE_METHOD,
	MONGODB_BINNING_FORMAT,
	ICONS_LIST,
	ICONS_COLORS,
	PAGE_ICONS_LIST
};
