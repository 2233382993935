/* eslint-disable no-lone-blocks */
/* eslint-disable no-inner-declarations */
import { Autocomplete, Chip, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import WhiteListItem from "./WhiteListItem";

const ArrayString = ({
	item,
	listOfApis,
	viewInvalid,
	path,
	disabled,
	value,
	whitelist,
	onChange,
	setViewInvalid,
	label
}) => {
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);

	const [search, setSearch] = useState("");

	const [optionAuto, setOptionAuto] = useState([]);

	let whitelistDynamic = item.whitelistDynamic || {};

	async function loadSuggestions(searchText, collection, searchFields, apiName, action, limit = 10) {
		if (!listOfApis.length) return [];

		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText };
			querryData.push(query);
		});

		let attribute = whitelistDynamic?.params?.attribute;

		querryData.push({ [attribute]: { $regex: searchText } });

		let dataQuerry = JSON.stringify({
			query: {
				$or: querryData
			}
		});

		let APIFind;
		let actionFind;
		if (apiName && action) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind?.config?.action[action];
		} else {
			return [];
		}

		let account = actionFind?.account ?? false;

		collection = collection || actionFind?.collection;

		// Merge collections is an array of collections name, that will be requested in the same time
		// The result will be merged in the same array
		const mergeCollections = actionFind?.mergeCollections || [];

		let config = {
			method: actionFind?.method ?? "GET",
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${collection}/items?search=${searchText}&page=1&limit=${limit}&account=${account}`,
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			}
		};

		if (config.method === "GET") {
			config.data = dataQuerry;
		} else {
			config.data = {
				filter: actionFind?.filter,
				searchFields,
				attribute,
				mergeCollections,
				query: {
					$or: querryData
				}
			};
		}

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});

		return result?.results || [];
	}

	async function loadSuggestionsInit() {
		let result = await loadSuggestions(
			"",
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	}

	const ValueChange = async valueC => {
		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params.searchFields.map(key => option[key]).join(" - ");
		label = labelAttribute + " - " + label;
		return label;
	};

	const onChangeDynamic = (event, values, reason) => {
		setSearch("");
		let newValues = [];

		values.forEach(val => {
			let attributeValue = val[whitelistDynamic.params.attribute];
			if (attributeValue) {
				newValues.push(attributeValue);
			}
		});

		let newValuesAuto = values.filter(val => val[whitelistDynamic.params.attribute]);

		onChange(newValues);

		if (reason === "clear") {
			loadSuggestionsInit();
		}
	};

	useEffect(() => {
		if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			loadSuggestionsInit();
		}
	}, [listOfApis]);

	/**
	 * WhiteList dynamic array handle
	 */
	if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
		return (
			<Autocomplete
				onChange={onChangeDynamic}
				multiple
				id="auto"
				options={optionAuto}
				getOptionLabel={getOptionLabel}
				// Tags (selected items) controller
				value={value.map(i => {
					return { [whitelistDynamic.params.attribute]: i };
				})}
				renderTags={(tags, getTagProps) => {
					return tags.map((option, index) => {
						return (
							<Chip label={option[whitelistDynamic.params.attribute]} {...getTagProps({ index })} />
						);
					});
				}}
				// Search controller (input)
				inputValue={search}
				renderInput={params => (
					<MDInput
						{...params}
						label={label}
						onChange={e => {
							setSearch(e.target.value);
							ValueChange(e.target.value);
						}}
					/>
				)}
			/>
		);
	}

	return (
		<Tooltip
			open={viewInvalid === path}
			title={i18n.t("FORMS.whitelistInvalidList")}
			placement="bottom"
			disableFocusListener
			disableHoverListener
			disableTouchListener
		>
			<Autocomplete
				disabled={disabled}
				value={lod_.isArray(value) ? value : []}
				onChange={(event, newValue) => {
					if (lod_.isEmpty(whitelist)) {
						onChange(newValue);
					} else {
						const stringValuesWhite = newValue.filter(val => whitelist.includes(val));
						if (stringValuesWhite.length === newValue.length) {
							onChange(stringValuesWhite);
						} else {
							setViewInvalid(path);
							setTimeout(() => {
								setViewInvalid("");
							}, 3000);
						}
					}
				}}
				multiple
				id="tags-filled"
				options={whitelist}
				freeSolo
				renderTags={(tags, getTagProps) => {
					return tags.map((option, index) => {
						return <Chip label={option} {...getTagProps({ index })} />;
					});
				}}
				renderInput={params => <MDInput {...params} label={label} />}
			/>
		</Tooltip>
	);
};

const InputString = ({
	label,
	disabled = false,
	value,
	onChange,
	item = {},
	path = "",
	listOfApis = [],
	error,
	...rest
}) => {
	const [viewInvalid, setViewInvalid] = useState("");

	let isArray = item.isArray || false;
	let whitelist = item.whitelist || [];

	return (
		<MDBox>
			{isArray ? (
				<ArrayString
					item={item}
					listOfApis={listOfApis}
					viewInvalid={viewInvalid}
					path={path}
					disabled={disabled}
					value={value}
					whitelist={whitelist}
					onChange={onChange}
					setViewInvalid={setViewInvalid}
					label={label}
				/>
			) : (
				<WhiteListItem
					disabled={disabled}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					error={error}
					{...rest}
				/>
			)}
		</MDBox>
	);
};
export default InputString;
