/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for MDTypography
import MDTypographyRoot from "components/Basics/MDTypography/MDTypographyRoot";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

const MDTypography = forwardRef(
	(
		{
			color = "dark",
			fontWeight = false,
			textTransform = "none",
			verticalAlign = "unset",
			textGradient = false,
			opacity = 1,
			children = false,
			...rest
		},
		ref
	) => {
		const [controller] = useMaterialUIController();
		const { darkMode } = controller;

		return (
			<MDTypographyRoot
				{...rest}
				ref={ref}
				ownerState={{
					color,
					textTransform,
					verticalAlign,
					fontWeight,
					opacity,
					textGradient,
					darkMode
				}}
			>
				{children}
			</MDTypographyRoot>
		);
	}
);

export default MDTypography;
