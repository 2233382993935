/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
import "./style.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import { display } from "redux-react/reducers/snackBarReducer";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import HTMLEditor from "components/Custom/HTMLEditor";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	MenuItem
} from "@mui/material";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import { t } from "i18next";
import AnswerItem from "components/Custom/AnswerItem";
import { queryDictionaries } from "helpers/dictionary";
import SignatureInformations from "./Signature/SignatureInformations";

/**
 * Signature component
 */
const SignatureComponent = ({ channel, common, onChange }) => {
	const [dictionaries, setDictionaries] = useState({});

	const dispatch = useDispatch();

	const setupDictionaries = async () => {
		const dictionariesList = await queryDictionaries(dispatch, ["contact", "context"]);
		setDictionaries(dictionariesList);
	};

	// Dialog showing if user wants to exit the editor (loose changes)
	const [exitDialogOpen, setExitDialogOpen] = useState(false);
	// Open the editor
	const [openEditor, setOpenEditor] = useState({
		open: false,
		editMode: false,
		html: ""
	});
	// Open the complete signature dialog
	const [completeSignatureOpen, setCompleteSignatureOpen] = useState({
		open: false,
		html: ""
	});
	// List of signatures
	const [signatures, setSignatures] = useState([]);

	/* Get all signatures for this channel */
	const getSignatures = async () => {
		dispatch(
			FormAction.getItemsFromCollection(
				"answerItem",
				{
					query: {
						documentType: "signature",
						channel: channel.type
					}
				},
				res => {
					setSignatures(res?.items ?? []);
				}
			)
		);
	};

	/* Add a new signature */
	const addSignature = async data => {
		/**
		 * Signature (answer item) structure
		 */
		const signature = {
			documentType: "signature",
			code: data.code,
			name: data.name,
			description: data.description ?? "",
			// Channel type
			channel: channel.type,
			// Channel code
			channelCode: common.code,
			content: {
				text: data.html
			},
			type: "signature",
			language: "ALL"
		};

		dispatch(
			FormAction.addItemEmpty(
				{
					values: signature,
					target: "answerItem",
					unique: { code: signature.code },
					actions: ["insertSignatureAction"]
				},
				res => {
					dispatch(
						display({
							message: t("CHANNEL.SIGNATURE.successfullyAdded"),
							type: "success"
						})
					);
					// If OK, close the editor
					setOpenEditor(prev => {
						return {
							open: false,
							editMode: false,
							html: ""
						};
					});
					// Close the complete signature dialog
					setCompleteSignatureOpen({ open: false, html: "" });
					// Reload the signatures
					getSignatures();
				}
			)
		);
	};

	/* Delete a signature */
	const deleteSignature = id => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			// Reload the signatures
			getSignatures();
		};
		dispatch(
			FormAction.deleteItem(
				id,
				"answerItem",
				{
					delete: true,
					actions: ["deleteSignatureAction"]
				},
				onSuccess
			)
		);
	};

	/* Handle the editor save */
	const htmlEditorSave = (html, editMode, id) => {
		let data = {
			values: {
				content: {
					text: html
				}
			},
			target: "answerItem",
			unique: [],
			actions: ["updateSignatureAction"]
		};

		if (editMode && id) {
			// If we are in edit mode, update the item
			dispatch(
				FormAction.updateItem(id, data, res => {
					setOpenEditor(prev => {
						return {
							open: false,
							editMode: false,
							html: ""
						};
					});
					getSignatures();
				})
			);
		} else {
			// If we are in add mode, show the complete signature dialog to complete the signature
			setCompleteSignatureOpen({
				open: true,
				html: html
			});
		}
	};

	/* On load, get the signatures */
	useEffect(() => {
		getSignatures();
		setupDictionaries();
	}, []);

	return (
		<MDBox sx={{ height: "100%", width: "100%" }}>
			<HTMLEditor
				open={openEditor.open}
				html={openEditor.html}
				editMode={openEditor.editMode}
				id={openEditor.id}
				onClose={html => {
					setExitDialogOpen(true);
				}}
				onSave={htmlEditorSave}
				variablesDictionary={dictionaries}
			/>
			<MDBox display="flex" flexDirection="row" justifyContent="space-between" mt={3} mb={3}>
				<MDTypography variant="h4">{t("CHANNEL.SIGNATURE.availableSignatures")}</MDTypography>
				<MDButton
					variant="contained"
					color="info"
					onClick={() =>
						setOpenEditor(prev => {
							return {
								open: true,
								editMode: false,
								html: ""
							};
						})
					}
				>
					{t("CHANNEL.SIGNATURE.addSignature")}
				</MDButton>
			</MDBox>

			{/* Signatures list */}
			<MDBox>
				<Grid container spacing={2}>
					{signatures.map((signature, index) => (
						<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
							<AnswerItem
								selected={common?.signatureCode === signature.code}
								content={signature.content.text}
								item={signature}
								items={onClose => {
									return [
										<MenuItem
											onClick={e => {
												e.stopPropagation();
												onClose();
												setOpenEditor(prev => {
													return {
														open: true,
														editMode: true,
														html: signature.content.text,
														id: signature._id
													};
												});
											}}
										>
											<Icon>edit</Icon>&nbsp;Modifier
										</MenuItem>,
										<ConfirmDialogButton
											onConfirm={e => {
												e.stopPropagation();
												onClose(e);
												deleteSignature(signature._id);
											}}
											onCancel={e => {
												e.stopPropagation();
												onClose();
											}}
											component={
												<MenuItem>
													<Icon>delete</Icon>&nbsp;Supprimer
												</MenuItem>
											}
											title={`${t("DIALOG.DELETE.delete")} "${signature.name}"`}
											content={`${t("DIALOG.DELETE.confirmationDelete")} "${signature.name}" ?`}
										/>
									];
								}}
								onClick={() => {
									// Change in common part
									onChange("signatureCode", signature.code);
								}}
							/>
						</Grid>
					))}
				</Grid>
			</MDBox>

			{/* Complete signature */}
			<SignatureInformations
				open={completeSignatureOpen.open}
				html={completeSignatureOpen.html}
				onClose={() => setCompleteSignatureOpen({ open: false, html: "" })}
				onSave={data => addSignature(data)}
			/>

			{/* Exit dialog confirmation */}
			<Dialog open={exitDialogOpen} onClose={() => setExitDialogOpen(false)} fullWidth maxWidth="md">
				<DialogTitle>{t("EDITOR.leaveEditor")}</DialogTitle>
				<DialogContent>{t("EDITOR.leaveEditorMessage")}</DialogContent>
				<DialogActions>
					<MDButton variant="outlined" color="info" onClick={() => setExitDialogOpen(false)}>
						{t("SETTINGS.cancel")}
					</MDButton>
					<MDButton
						variant="contained"
						color="error"
						onClick={() => {
							setExitDialogOpen(false);
							setOpenEditor(prev => {
								return {
									open: false,
									editMode: false,
									html: ""
								};
							});
						}}
					>
						{t("SETTINGS.confirm")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</MDBox>
	);
};

export default SignatureComponent;
