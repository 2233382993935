/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Labels settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Paper,
	Popper
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { ChromePicker, TwitterPicker } from "react-color";
import i18n from "i18n";

const HEXGenerator = () => {
	return "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
};

export default function AddLabelDialog({
	open,
	handleCloseDialog,
	handleSave,
	possibleCode = null
}) {
	const [code, setCode] = useState("");
	const [label, setLabel] = useState("");
	const [color, setColor] = useState({ hex: "#222" });
	const [pickerRef, setPickerRef] = useState(null);

	const isDisabled = !code || !label || !color;

	function handleColorSelection(color) {
		setColor(color);
	}

	function close(e, reason) {
		if (pickerRef) {
			setPickerRef(null);
			return;
		}
		handleCloseDialog();
		setCode("");
		setLabel("");
		// setColor({ hex: "#222" });
		setPickerRef(null);
	}

	function sumbit() {
		if (!code || !label || !color) return;
		handleSave({
			code,
			label,
			color: color.hex
		});
		close();
	}

	function displayPicker(e) {
		setPickerRef(e.target);
	}

	useEffect(() => {
		if (open) setColor({ hex: HEXGenerator() });
		if (possibleCode) {
			setCode(possibleCode?.trim());
		}
	}, [open]);

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={close}>
			<DialogTitle>{i18n.t("SETTINGS.LABELS.add")}</DialogTitle>
			<DialogContent>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						label={i18n.t("SETTINGS.LABELS.code")}
						value={code}
						onChange={e => setCode(e.target.value)}
					/>
				</MDBox>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						label={i18n.t("SETTINGS.LABELS.label")}
						value={label}
						onChange={e => setLabel(e.target.value)}
					/>
				</MDBox>
				<MDBox mt={1} className="colorContainer">
					<MDBox className="colorPreset">
						<TwitterPicker
							width="100%"
							triangle="hide"
							color={color}
							onChange={handleColorSelection}
						/>
					</MDBox>
					<MDBox
						className="buttonColorPicker"
						style={{
							backgroundColor: color.hex
						}}
						onClick={displayPicker}
					>
						<Icon fontSize="medium" className="buttonColorPickerIcon">
							colorize
						</Icon>
					</MDBox>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="info" onClick={sumbit}>
					{i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
			<Popper
				id={pickerRef ? "simple-popper" : ""}
				open={Boolean(pickerRef)}
				anchorEl={pickerRef}
				placement="right"
				style={{
					zIndex: 10000
				}}
			>
				{() => (
					<ClickAwayListener
						onClickAway={e => {
							setPickerRef(null);
						}}
					>
						<Paper>
							<ChromePicker disableAlpha color={color} onChange={handleColorSelection} />
						</Paper>
					</ClickAwayListener>
				)}
			</Popper>
		</Dialog>
	);
}
