import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { createDictionarySkeleton } from "helpers/form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import MDBadge from "components/Basics/MDBadge";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";

/**
 * Drawer pannel to edit a shop
 * @param {*} param0
 * @returns
 */
const EditShopForm = ({ dictionary, code, onClose, onSave, onDelete, type = "create" }) => {
	const dispatch = useDispatch();
	const [currentShop, setCurrentShop] = useState(null);
	const [skeleton, setSkeleton] = useState({});

	const [propositionsMenuRef, setPropositionsMenuRef] = useState(null);

	// Possible shops list, it will be displayed in the propositions menu
	const possibleShops = (currentShop?.extra?.possibleShops ?? []).filter(
		shop => shop.place_id !== currentShop.googleID
	);

	/**
	 * Change a value in the skeleton
	 * @param {string} path - Path to the value
	 * @param {any} value - New value
	 */
	const onChange = (path, value) => {
		setSkeleton(prev => {
			let newSkeleton = lod_.cloneDeep(prev);
			lod_.set(newSkeleton, path, value);
			return newSkeleton;
		});
	};

	useEffect(() => {
		if (type === "create") {
			// Create an empty skeleton
			setSkeleton(createDictionarySkeleton(dictionary, {}));
		} else if (type === "edit") {
			// Get the shop from the collection
			dispatch(
				FormActions.getItemsFromCollection(
					"externalGooglePlace",
					{
						query: {
							code,
							active: { $in: [true, false, null] }
						}
					},
					res => {
						const shop = res.items[0];
						if (shop) {
							// Set the shop and the skeleton
							setCurrentShop(shop);
							setSkeleton(createDictionarySkeleton(dictionary, shop));
						}
					}
				)
			);
		}
	}, [code]);

	// Return null if the skeleton is empty
	if (lod_.isEmpty(skeleton)) {
		return null;
	}

	return (
		<MDBox
			display="flex"
			style={{
				flexDirection: "column",
				justifyContent: "space-between",
				height: "100%",
				overflowY: "auto"
			}}
		>
			<MDBox
				style={{
					height: "100%",
					overflowY: "auto"
				}}
			>
				<MDBox display="flex" justifyContent="space-between" mb={2} mt={2} mr={2}>
					<MDTypography variant="h4" component="h4" gutterBottom sx={{ mb: 1, mt: 1 }}>
						{`${type === "create" ? "Ajouter" : "Modifier"} une fiche Google`}
					</MDTypography>

					{/*
					 * Possible shops button
					 */}
					{type === "edit" && (
						<Tooltip
							placement="top"
							title={
								lod_.isEmpty(possibleShops)
									? ""
									: `${possibleShops.length} fiche Google peuvent correspondre`
							}
						>
							<MDBadge badgeContent={possibleShops.length} color="error" size="xs" circular>
								<MDButton
									color="info"
									size="small"
									disabled={lod_.isEmpty(possibleShops)}
									onClick={e => {
										setPropositionsMenuRef(e.currentTarget);
									}}
								>
									<Icon>auto_awesome</Icon>&nbsp;Propositions
								</MDButton>
							</MDBadge>
						</Tooltip>
					)}

					<Menu
						anchorEl={propositionsMenuRef}
						open={Boolean(propositionsMenuRef)}
						onClose={() => setPropositionsMenuRef(null)}
					>
						{possibleShops.map((shop, index) => {
							return (
								<MenuItem
									disabled={shop.place_id === currentShop.googleID}
									key={index}
									onClick={() => {
										const mergedShop = {
											...currentShop,
											address: shop.formatted_address,
											active: true,
											configured: true,
											coordinates: {
												lat: shop.geometry.location.lat,
												long: shop.geometry.location.lng
											},
											googleID: shop.place_id,
											url: `https://www.google.com/maps/place/?q=place_id:${shop.place_id}`
										};
										setSkeleton(createDictionarySkeleton(dictionary, mergedShop));
										setCurrentShop(mergedShop);
										setPropositionsMenuRef(null);
									}}
								>
									<MDBox>
										<MDTypography variant="h6" component="h6" fontSize="small">
											{shop.name}
										</MDTypography>
										<MDTypography variant="body2" component="body2" fontSize="small">
											{shop.formatted_address}
										</MDTypography>
									</MDBox>
								</MenuItem>
							);
						})}
					</Menu>
				</MDBox>

				{/*
				 * Info box of the shop
				 * Displayed only in edit mode
				 */}
				{type === "edit" && (
					<MDBox
						mb={2}
						p={1}
						borderRadius="md"
						shadow="md"
						display="flex"
						flexDirection="column"
						bgColor="success"
						style={{
							backgroundColor: currentShop?.configured ? "#A8D5BA" : "#F4A6A6"
						}}
					>
						<MDTypography variant="h6" fontSize="medium">
							{currentShop?.configured
								? "Fiche Google operationnelle"
								: "Fiche Google non operationnelle"}
						</MDTypography>
						<MDTypography variant="body2" fontSize="small">
							{currentShop?.configured
								? "Cette fiche à été associée à un business Google"
								: "Cette fiche n'a pas été associée à un business Google"}
						</MDTypography>
					</MDBox>
				)}
				{/*
				 * Shop form
				 */}
				<LittleForm
					object={dictionary}
					metadatasSkeleton={skeleton}
					handleChange={(path, value) => {
						onChange(path, value);
					}}
				/>
			</MDBox>
			<MDBox flex="1" display="flex" justifyContent="space-between" flexDirection="row">
				<MDBox>
					<ConfirmDialogButton
						onConfirm={(e, values) => {
							onDelete(currentShop ?? {});
						}}
						component={
							<MDButton variant="contained" color="error" disabled={type === "create"}>
								Supprimer
							</MDButton>
						}
						rowValues={currentShop ?? {}}
						displayAttribute="name"
					/>
				</MDBox>
				<MDBox>
					<MDButton variant="outlined" color="info" onClick={onClose} sx={{ mr: 1 }}>
						Annuler
					</MDButton>
					<MDButton
						variant="contained"
						color="info"
						onClick={() => {
							onSave({ datas: skeleton, shop: currentShop });
						}}
					>
						Sauvegarder
					</MDButton>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default EditShopForm;
