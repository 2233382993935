/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
import { useEffect, useRef, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import {
	Divider,
	FormControl,
	Icon,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import MDButton from "components/Basics/MDButton";
import InputString from "components/Custom/LittleForm/Inputs/InputString";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import i18n from "i18n";
import { display } from "redux-react/reducers/snackBarReducer";
import MDBadge from "components/Basics/MDBadge";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import { COLLECTIONS } from "../../constants";

const ConfigInput = ({ model, onChange, config, newModel = false }) => {
	const inputRef = useRef(null);
	const [value, setValue] = useState("");
	const [isSecret, setIsSecret] = useState(false);

	const resetSecretInput = () => {
		setIsSecret(false);
		setValue("");

		let configClone = lod_.cloneDeep(model.config);
		lod_.set(configClone, config.code, "");
		onChange("config", configClone);
	};

	const backupSecretInput = () => {
		setIsSecret(true);
		setValue("*".repeat(40));

		let backupValue = model.config[config.code] || "";

		let configClone = lod_.cloneDeep(model.config);
		lod_.set(configClone, config.code, backupValue);
		onChange("config", configClone);
	};

	useEffect(() => {
		if (newModel) return;

		if (config.secret) {
			setIsSecret(true);
			setValue("*".repeat(40));
		} else {
			setValue(model.config[config.code] || "");
		}
	}, [model.code]);

	// Blur the input field when the secret is displayed
	const getInputStyle = () => {
		if (newModel) return {};
		if (isSecret) {
			return {
				color: "transparent",
				textShadow: "0 0 5px rgba(0,0,0,0.5)"
			};
		}
		return {};
	};

	// When editing a model, the input field is hidden and replaced by a button to edit the secret
	const getInputAdornment = () => {
		if (newModel) return null;
		if (isSecret) {
			return (
				<InputAdornment position="end">
					<MDButton
						style={{ pointerEvents: "visible" }}
						size="small"
						onClick={() => {
							resetSecretInput();
						}}
						variant="contained"
						color="info"
					>
						<Icon>edit</Icon>&nbsp;Modifier
					</MDButton>
				</InputAdornment>
			);
		}
		if (config.secret) {
			return (
				<InputAdornment position="end">
					<MDButton
						size="small"
						onClick={() => {
							backupSecretInput();
						}}
						variant="outlined"
						color="info"
					>
						<Icon>close</Icon>&nbsp;Annuler
					</MDButton>
				</InputAdornment>
			);
		}
		return null;
	};

	return (
		<InputString
			sx={{ mt: 2 }}
			disabled={isSecret && !newModel}
			label={config.code}
			value={isSecret ? value : model.config[config.code] || ""}
			onChange={newValue => {
				let configClone = lod_.cloneDeep(model.config);
				lod_.set(configClone, config.code, newValue);
				onChange("config", configClone);
			}}
			ref={inputRef}
			inputProps={{
				style: getInputStyle()
			}}
			InputProps={{
				endAdornment: getInputAdornment()
			}}
		/>
	);
};

/**
 * Mandatory field for inputs
 * @param {*} param0
 * @returns
 */
const MandatoryField = ({ text }) => {
	return (
		<div>
			{text}&nbsp;<span style={{ color: "red" }}>*</span>
		</div>
	);
};

/**
 * Main component, display the model edition
 * @param {*} param0
 * @returns
 */
const ModelEdition = ({
	model: modelProps = null,
	llmModelProvider,
	llmModelCategory,
	llmDocumentType,
	newModel = false,
	updateModelList
}) => {
	const dispatch = useDispatch();

	const [config, setConfig] = useState([]);
	const [options, setOptions] = useState([]);

	const [model, setModel] = useState(null);

	/* Check if button must be disabled  */
	const isDisabled = () => {
		if (
			!model?.name ||
			!model?.code ||
			!model?.provider ||
			!model?.category ||
			!model?.documentType
		) {
			return true;
		}
		return false;
	};

	/* Change propertie of model */
	const onChange = (path, value) => {
		let modelCopy = lod_.cloneDeep(model);
		lod_.set(modelCopy, path, value);
		setModel(modelCopy);
	};

	/* Delete the model */
	const deleteModel = () => {
		if (!model._id) return;

		dispatch(
			FormAction.deleteItem(model._id, "llmModel", { delete: true }, res => {
				dispatch(
					display({
						message: i18n.t("PROMPT.MODEL.EDITION.successfullyDeletedModel"),
						type: "success"
					})
				);
				updateModelList();
			})
		);
	};

	/* Save current changes */
	const submit = () => {
		let copyModel = lod_.cloneDeep(model);
		delete copyModel._id;

		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("PROMPT.MODEL.EDITION.successfullySavedModel"),
					type: "success"
				})
			);
			// Refresh the list of models after changes
			updateModelList(copyModel?.code);
		};

		let data = {
			values: copyModel,
			target: "llmModel",
			unique: { code: copyModel.code },
			actions: []
		};
		if (newModel) {
			dispatch(
				FormAction.addItemEmpty(data, onSuccess, err => {
					dispatch(
						display({
							message: i18n.t("PROMPT.MODEL.EDITION.cannotAddModel"),
							type: "error"
						})
					);
				})
			);
		} else {
			dispatch(
				FormAction.updateItem(model._id, data, onSuccess, err => {
					dispatch(
						display({
							message: i18n.t("PROMPT.MODEL.EDITION.cannotUpdateModel"),
							type: "error"
						})
					);
				})
			);
		}
	};

	/* Dusplicate model */
	const duplicateModel = () => {
		dispatch(
			FormAction.duplicateItem(
				{
					query: { code: model.code },
					collection: "llmModel",
					updateFields: ["code"]
				},
				res => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateSuccess"),
							type: "success"
						})
					);
					// Refresh the list of models after changes
					updateModelList(res?.result?.item?.code);
				}
			)
		);
	};

	/* Convert old format to new format */
	function convertModelToFormatPivot(oldModelFormat) {
		let provider = oldModelFormat.provider || oldModelFormat.modelProvider || "";
		let providerConfig = llmModelProvider.find(p => p.code === oldModelFormat.provider);

		let oldModelConfigAttribute = providerConfig?.config || "";
		let oldModelOptionsAttribute = providerConfig?.options || "";

		let newModelFormat = {
			_id: oldModelFormat._id || "",
			documentType: oldModelFormat.documentType || "",
			name: oldModelFormat.name || i18n.t("PROMPT.MODEL.EDITION.newModelName"),
			description: oldModelFormat.description || "",
			code: oldModelFormat.code || "",
			provider: provider,
			category: oldModelFormat.category || "",
			config: oldModelFormat.config || oldModelFormat[oldModelConfigAttribute] || {},
			options: oldModelFormat.options || oldModelFormat[oldModelOptionsAttribute] || {}
		};

		return newModelFormat;
	}

	useEffect(() => {
		setModel(
			convertModelToFormatPivot(
				modelProps || {
					documentType: "llmModel",
					name: "",
					description: "",
					code: "",
					provider: "",
					category: "",
					config: {},
					options: {}
				}
			)
		);
	}, [modelProps?.code]);

	useEffect(() => {
		if (!model) return;
		/* Get model config */
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModelConfig,
				{
					query: {
						assistantID: { $exists: false },
						documentType: model.documentType,
						provider: model.provider
					},
					catalog: "AI"
				},
				res => {
					if (res.items.length) {
						let config = res.items[0].config;
						setConfig(config);
					} else {
						setConfig([]);
					}
				}
			)
		);
		/* Get model options */
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModelOptions,
				{
					query: {
						assistantID: { $exists: false },
						documentType: model.documentType,
						provider: model.provider
					},
					catalog: "AI"
				},
				res => {
					if (res.items.length) {
						let options = res.items[0].options;
						setOptions(options);
					} else {
						setOptions([]);
					}
				}
			)
		);
	}, [modelProps?.code, model?.provider, model?.documentType]);

	if (!model) {
		return null;
	}

	return (
		<MDBox style={{ width: "100%", height: "100%", position: "relative", overflowY: "auto" }}>
			<MDBox
				m={1}
				style={{ paddingBottom: "50px", height: "100%" }}
				display="flex"
				flexDirection="column"
			>
				{/* Top infos */}
				<MDBox
					mb={2}
					display="flex"
					alignItems="center"
					justifyContent={newModel ? "space-between" : ""}
				>
					<MDTypography variant="h4">{model.name}</MDTypography>
					{newModel ? (
						<MDBox bgColor="info" sx={{ minWidth: 100 }} borderRadius="md">
							<FormControl fullWidth size="small">
								<Select
									labelId="select-label"
									id="select"
									value={model.documentType}
									onChange={e => {
										onChange("documentType", e.target.value);
									}}
									sx={{
										boxShadow: "none",
										".MuiOutlinedInput-notchedOutline": { border: 0 },
										"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
											border: 0
										},
										"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
											border: 0
										},
										".MuiSelect-icon": {
											display: "block",
											fill: "white"
										},
										".MuiSelect-select": {
											color: `white !important`,
											fontWeight: "bold"
										}
									}}
								>
									{llmDocumentType.map((document, index) => {
										return (
											<MenuItem key={index} value={document.code}>
												{document.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</MDBox>
					) : (
						<MDBadge
							sx={{ ml: 1 }}
							circular
							variant="contained"
							badgeContent={llmDocumentType.find(doc => doc.code === model.documentType)?.name}
							container
						></MDBadge>
					)}
				</MDBox>
				{/* Content */}
				<MDBox flex="1">
					<InputString
						sx={{ mt: 1 }}
						label={<MandatoryField text={i18n.t("PROMPT.MODEL.EDITION.modelCode")} />}
						value={model.code}
						onChange={value => onChange("code", value)}
					/>
					<InputString
						sx={{ mt: 2 }}
						label={<MandatoryField text={i18n.t("PROMPT.MODEL.EDITION.modelName")} />}
						value={model.name}
						onChange={value => onChange("name", value)}
					/>
					<InputString
						sx={{ mt: 2 }}
						label={i18n.t("PROMPT.MODEL.EDITION.modelDescription")}
						value={model.description}
						onChange={value => onChange("description", value)}
					/>
					<Divider />
					{/* Provider */}
					<FormControl sx={{ mt: 1.5, width: "100%" }}>
						<InputLabel id="select-model-provider-label">
							<MandatoryField text={i18n.t("PROMPT.MODEL.EDITION.modelProvider")} />
						</InputLabel>
						<Select
							labelId="select-model-provider-label"
							id="select-model-provider"
							label={<MandatoryField text={i18n.t("PROMPT.MODEL.EDITION.modelProvider")} />}
							value={model.provider || ""}
							onChange={e => onChange("provider", e.target.value)}
						>
							{llmModelProvider
								.filter(prov => prov.documentType === model?.documentType)
								.map((modelProvider, index) => {
									return (
										<MenuItem key={index} value={modelProvider.code}>
											<MDBox display="flex" alignItems="center">
												<MDBox mr={1} display="flex" alignItems="center">
													<img src={modelProvider.image} alt="OpenAI" width="20" />
												</MDBox>
												<MDTypography variant="h6">{modelProvider.name}</MDTypography>
											</MDBox>
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
					{/* Category */}
					<FormControl sx={{ mt: 1.5, width: "100%" }}>
						<InputLabel id="select-model-category-label">
							<MandatoryField text={i18n.t("PROMPT.MODEL.EDITION.modelCagegory")} />
						</InputLabel>
						<Select
							labelId="select-model-category-label"
							id="select-model-category"
							label={<MandatoryField text={i18n.t("PROMPT.MODEL.EDITION.modelCagegory")} />}
							value={model.category || ""}
							onChange={e => onChange("category", e.target.value)}
						>
							{llmModelCategory.map((category, index) => {
								return (
									<MenuItem key={index} value={category.code}>
										{category.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<Divider />
					{/* Config */}
					<MDTypography variant="h5">
						{i18n.t("PROMPT.MODEL.EDITION.configurationTitle")}
					</MDTypography>
					{config.map((config, index) => {
						return (
							<ConfigInput
								onChange={onChange}
								config={config}
								model={model}
								newModel={newModel}
								key={index}
							/>
						);
					})}
					<Divider />
					{/* Options */}
					<MDTypography variant="h5">{i18n.t("PROMPT.MODEL.EDITION.optionsTitle")}</MDTypography>
					{options.map((option, index) => {
						let value = model.options[option.code] || "";
						return (
							<InputString
								sx={{ mt: 2 }}
								key={index}
								label={option.code}
								value={value}
								onChange={newValue => {
									let options = lod_.cloneDeep(model.options);
									lod_.set(options, option.code, newValue);
									onChange("options", options);
								}}
							/>
						);
					})}
				</MDBox>
			</MDBox>
			{/* Bottom bar save */}
			<MDBox
				bgColor="white"
				p={1}
				style={{
					position: "sticky",
					bottom: 0,
					boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
				}}
				display="flex"
				justifyContent="space-between"
			>
				<MDBox display="flex">
					<ConfirmDialogButton
						onConfirm={() => {
							deleteModel();
						}}
						component={
							<MDButton variant="contained" color="error">
								<Icon>delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
							</MDButton>
						}
						title={`${i18n.t("DIALOG.DELETE.delete")} ${model.name}`}
						content={`${i18n.t("DIALOG.DELETE.confirmationDelete")} ${model.name} ?`}
					/>
					<MDButton
						sx={{ ml: 1 }}
						variant="outlined"
						color="info"
						onClick={() => {
							duplicateModel();
						}}
					>
						<Icon>copy</Icon>&nbsp;Dupliquer
					</MDButton>
				</MDBox>
				<MDBox>
					<MDButton
						variant="contained"
						color="info"
						onClick={() => {
							submit();
						}}
						disabled={isDisabled()}
					>
						<Icon>save</Icon>&nbsp;{i18n.t("SETTINGS.save")}
					</MDButton>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ModelEdition;
