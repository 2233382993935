/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";

// Custom styles for the SidenavCollapse
import {
	collapseItem,
	collapseIconBox,
	collapseIcon,
	collapseText,
	collapseArrow
} from "components/Advanced/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Tooltip } from "@mui/material";

function SidenavCollapse({
	icon,
	name,
	children = false,
	active = false,
	noCollapse = false,
	open = false,
	...rest
}) {
	const [controller] = useMaterialUIController();
	const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

	return (
		<>
			<ListItem component="li">
				<MDBox
					{...rest}
					sx={theme => collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })}
				>
					<ListItemIcon
						sx={theme => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
					>
						{typeof icon === "string" ? (
							<Icon sx={theme => collapseIcon(theme, { active })}>{icon}</Icon>
						) : (
							icon
						)}
					</ListItemIcon>

					<Tooltip placement="right" title={name}>
						<ListItemText
							// primary={name}
							sx={theme =>
								collapseText(theme, {
									miniSidenav,
									transparentSidenav,
									whiteSidenav,
									active
								})
							}
						>
							<div
								style={{
									display: "inline-block",
									width: "120px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									lineHeight: "normal"
								}}
							>
								{name}
							</div>
						</ListItemText>
					</Tooltip>

					<Icon
						sx={theme =>
							collapseArrow(theme, {
								noCollapse,
								transparentSidenav,
								whiteSidenav,
								miniSidenav,
								open,
								active,
								darkMode
							})
						}
					>
						expand_less
					</Icon>
				</MDBox>
			</ListItem>
			{children && (
				<Collapse in={open} unmountOnExit>
					{children}
				</Collapse>
			)}
		</>
	);
}

export default SidenavCollapse;
