import { WhatsApp, Facebook, Instagram, DeviceUnknown, Title } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { socket } from "redux-react/middleware/ws";
import MDBox from "components/Basics/MDBox";
import { LittleForm } from "components/Custom/LittleForm";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { t } from "i18next";
import { Box, List, ListItem } from "@mui/material";

// List possible subtypes to access easily in code
const possibleMetaSubtypes = {
	WA: "WA",
	FB: "FB",
	IS: "IS"
};

/**
 * Get facebook login url, that allow to request a delegation via OAuth2, with many parameters
 * @param {string} subType The channel subtype, see possibleMetaSubtypes
 * @param {object} metaConfig The meta platformConfig.CONFIG
 * @param {object} state An free object that is passed through oauth to have some context on /oauth success call route on cm-facebook
 * @returns {string} The facebook login url
 */
function getFacebookLoginUrl(subType, metaConfig, state) {
	const { APP_ID, REDIRECT_URI, APP_WHATSAPP_CONFIGURATION_ID, types, facebookLoginApi } =
		metaConfig || {};
	const { host, version } = facebookLoginApi?.config || {};
	const { scope } = types[subType];

	const params = {
		app_id: APP_ID,
		redirect_uri: REDIRECT_URI,
		scope,
		response_type: "code",
		state: JSON.stringify(state)
	};

	if (subType === possibleMetaSubtypes.WA) {
		params.config_id = APP_WHATSAPP_CONFIGURATION_ID;
	}

	const loginRoute = `https://${host}/${version}/dialog/oauth`;

	return `${loginRoute}?${new URLSearchParams(params).toString()}`;
}

/**
 * Helper function to get the correct logo
 * @param {string} subType one of the possibleMetaSubtypes values, WA, FB, IS
 * @returns {JSX.Element} the icon of the subtype
 */
function getChannelIcon(subType) {
	switch (subType) {
		case possibleMetaSubtypes.WA: {
			return <WhatsApp />;
		}
		case possibleMetaSubtypes.FB: {
			return <Facebook />;
		}
		case possibleMetaSubtypes.IS: {
			return <Instagram />;
		}
		default: {
			return <DeviceUnknown />;
		}
	}
}

/**
 * Helper function to get a minimal List about what access is currently granted if any
 * @param {string} subType one of the possibleMetaSubtypes values, WA, FB, IS
 * @param {object} config the current config of the channel
 * @returns {JSX.Element} the JSX element
 */
function MetaConnectionInfo({ subType, config }) {
	switch (subType) {
		case possibleMetaSubtypes.WA: {
			return (
				<Box>
					<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
						Businesses
					</MDTypography>
					<List>
						{config?.businesses?.map(business => {
							return (
								<ListItem>
									<MDTypography key={business.id} fontSize="small">
										{business.name}(ID: {business.id}) with WhatsAppBusinessAccount:
									</MDTypography>
									<List>
										{business?.whatsappBusinessAccounts?.map(account => {
											return (
												<ListItem>
													<MDTypography key={account.id} fontSize="small">
														{account.name}(ID: {account.id}) with PhoneNumbers:
													</MDTypography>
													<List>
														{account?.phoneNumbers?.map(number => {
															return (
																<MDTypography key={number.id} fontSize="small">
																	{number.display_phone_number} {number.verified_name}(ID:{" "}
																	{number.id})
																</MDTypography>
															);
														})}
													</List>
												</ListItem>
											);
										})}
									</List>
								</ListItem>
							);
						})}
					</List>
				</Box>
			);
		}
		case possibleMetaSubtypes.FB:
		case possibleMetaSubtypes.IS: {
			return <List></List>;
		}
		default: {
			return <List></List>;
		}
	}
}

const MetaConfiguration = ({
	metaConfig,
	channelCode,
	config,
	onChange,
	setConfigPart,
	subType,
	dictionary,
	configSkeleton
}) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const metaTokenLoaded = config?.PAGE_ACCESS_TOKEN;

	const state = {
		ws: user.userID,
		action: "create",
		type: subType, // In cockpitAdmin subtype is a notion for config channel, in other places it is a type so rename it
		channelCode,
		assistantID: profile.assistantID
	};

	const url = getFacebookLoginUrl(subType, metaConfig, state);

	const openAuthorizationIframe = () => {
		window.open(url, "_blank", "width=600,height=800").focus();
	};

	/**
	 * Result function of websocket event completeConfigChannelCreation
	 * Got when user creates a channel
	 * @param {*} { channel }
	 * @returns
	 */
	const completeConfigChannelCreation = ({ channel }) => {
		if (channel.code !== channelCode) {
			return;
		}

		let channelConfig = channel.config;
		let update = {
			PAGE_ACCESS_TOKEN: channelConfig.PAGE_ACCESS_TOKEN
		};
		switch (subType) {
			case possibleMetaSubtypes.WA: {
				// V1 we prefil with the first account and first whatsapp number
				const firstWhatsappBusinessAccounts =
					channelConfig?.businesses?.[0]?.whatsappBusinessAccounts?.[0] || {};
				const apiKey = firstWhatsappBusinessAccounts?.id;
				const whatsappPhoneNumberID = firstWhatsappBusinessAccounts?.phoneNumbers?.[0]?.id;

				update.businesses = channelConfig?.businesses; // Store all loaded info for debug
				update.pin = channelConfig?.pin; // Also save pin to the config

				if (apiKey) {
					update.apiKey = apiKey;
					// Make sure to clean whatsappPhoneNumberID if we change the API key
					if (config.apiKey !== apiKey) {
						update.whatsappPhoneNumberID = "";
					}
				}
				if (whatsappPhoneNumberID) {
					update.whatsappPhoneNumberID = whatsappPhoneNumberID;
				}

				break;
			}
			case possibleMetaSubtypes.FB:
			case possibleMetaSubtypes.IS: {
				// V1 we prefil with the first page

				const firstPage = channelConfig?.pages?.[0] || {};
				const PAGE_ID = firstPage?.id;
				const PAGE_NAME = firstPage?.name;

				if (PAGE_ID) {
					update.apiKey = PAGE_ID;
					update.PAGE_ID = PAGE_ID;
				}
				if (PAGE_NAME) {
					update.PAGE_NAME = PAGE_NAME;
				}
				break;
			}
			default: {
				break;
			}
		}

		setConfigPart(prev => {
			// Update only the page access token
			return {
				...prev,
				...update
			};
		});
	};

	useEffect(() => {
		// Create socket listeners
		socket.on("completeConfigChannelCreation", completeConfigChannelCreation);

		return () => {
			socket.off("completeConfigChannelCreation", completeConfigChannelCreation);
		};
	}, []);

	return (
		<MDBox sx={{ mt: 4, flex: 1 }}>
			{/*
			 * When user is not connected to meta
			 */}

			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				style={{
					height: metaTokenLoaded ? "unset" : "100%"
				}}
			>
				<MDTypography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
					{metaTokenLoaded ? t("CHANNEL.META.connected") : t("CHANNEL.META.connectToMeta")}
				</MDTypography>
				{/* <MetaConnectionInfo subType={subType} config={config} /> */}
				<MDButton color="info" onClick={openAuthorizationIframe}>
					{getChannelIcon(subType)}
					<MDBox sx={{ ml: 2 }} color="white">
						{metaTokenLoaded ? t("CHANNEL.META.refreshAccountAccess") : t("CHANNEL.META.connect")}
					</MDBox>
				</MDButton>
			</MDBox>

			{/*
			 * Display configuration
			 */}
			{metaTokenLoaded && (
				<MDBox>
					<LittleForm
						object={dictionary}
						metadatasSkeleton={configSkeleton}
						handleChange={(path, value) => {
							onChange(path, value);
						}}
					/>
				</MDBox>
			)}
		</MDBox>
	);
};

export default MetaConfiguration;
