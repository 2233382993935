/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for the MDBadge
import MDBadgeRoot from "components/Basics/MDBadge/MDBadgeRoot";

const MDBadge = forwardRef(
	(
		{
			color = "info",
			variant = "gradient",
			size = "sm",
			circular = false,
			indicator = false,
			border = false,
			container = false,
			children = false,
			...rest
		},
		ref
	) => (
		<MDBadgeRoot
			{...rest}
			ownerState={{ color, variant, size, circular, indicator, border, container, children }}
			ref={ref}
			color="default"
		>
			{children}
		</MDBadgeRoot>
	)
);

export default MDBadge;
