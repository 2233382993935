/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for MDBox
import MDBoxRoot from "components/Basics/MDBox/MDBoxRoot";

const MDBox = forwardRef(
	(
		{
			variant = "contained",
			bgColor = "transparent",
			color = "dark",
			opacity = 1,
			borderRadius = "none",
			shadow = "none",
			coloredShadow = "none",
			...rest
		},
		ref
	) => (
		<MDBoxRoot
			{...rest}
			ref={ref}
			ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
		/>
	)
);

export default MDBox;
