/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import lod_ from "lodash";
import { useEffect } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import i18n from "i18n";
import { IconButton } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

function PagesDataTable({
	draggable,
	dragHandler,
	table,
	noEndBorder,
	display,
	actionDeleteHandle,
	actionEditHandle,
	actions
}) {
	const columns = table.columns;
	const data = table.rows;

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, setPageSize, page } =
		tableInstance;

	/**
	 * Check if the table has actions
	 */
	const hasActions = actionEditHandle || actionDeleteHandle;

	/**
	 * ##############################
	 * Handle specials actions in props
	 * -> allow to custom actions with row values
	 * e.g : disabe, click, loadingn style
	 * ##############################
	 */
	function overrideObjectProps(action, row) {
		// custom disabled method
		const checkDisabled = act => {
			if (act.props?.disabled) {
				return act.props.disabled;
			} else if (act.props?.customdisabled) {
				return act.props.customdisabled(row);
			} else {
				return false;
			}
		};
		// custom style
		const customStyle = act => {
			let style = act.props?.style ?? {};
			if (act.props?.customstyle) {
				style = act.props.customstyle(row, style);
			}
			return style;
		};

		const customLoading = act => {
			if (act.props?.loading) {
				let loadingObject = act.props.loading(row);
				if (loadingObject.loading) {
					return loadingObject.component;
				}
			}
			return act.props?.children;
		};

		action = {
			...action,
			props: {
				...action.props,
				onClick(e) {
					action.props?.handleclick ? action.props?.handleclick(row, e) : null;
				},
				disabled: checkDisabled(action),
				style: customStyle(action),
				children: customLoading(action),
				// Pass extra param to props rowvalues
				rowvalues: row.values
			}
		};

		if (action.props.children && lod_.isObject(action.props.children)) {
			action.props.children = overrideObjectProps(action.props.children, row);
		}

		return action;
	}

	useEffect(() => setPageSize(1000), []);

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			<DragDropContext
				onDragEnd={({ destination, source }) => {
					if (!source || !destination) return;
					dragHandler({ from: source.index, to: destination.index });
				}}
			>
				<Table {...getTableProps()}>
					<MDBox component="thead">
						{headerGroups.map((headerGroup, groupIndex) => (
							<TableRow {...headerGroup.getHeaderGroupProps()} key={groupIndex}>
								<DataTableHeadCell width={10} align="left" sorted={false}></DataTableHeadCell>
								{headerGroup.headers.map((column, index) => {
									if (display.includes(column.id)) {
										return (
											<DataTableHeadCell key={index} width="auto" align="left" sorted={false}>
												{column.render("Header")}
											</DataTableHeadCell>
										);
									}
									return null;
								})}
								<DataTableHeadCell width={20} align="right" sorted={false}>
									Actions
								</DataTableHeadCell>
							</TableRow>
						))}
					</MDBox>
					<Droppable droppableId="dnd-list" direction="vertical">
						{provided => (
							<TableBody
								{...getTableBodyProps()}
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{page.map((row, key) => {
									prepareRow(row);
									return (
										<Draggable
											isDragDisabled={!draggable}
											key={row.id}
											index={key}
											draggableId={row.id}
										>
											{provided => (
												<TableRow
													{...row.getRowProps()}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<DataTableBodyCell>
														<Icon fontSize="medium">apps</Icon>
													</DataTableBodyCell>
													{row.cells.map((cell, index) => {
														if (display.includes(cell.column.id)) {
															let style = {};
															switch (cell.row.values.type) {
																case "title":
																	style.fontWeight = "bold";
																	style.textTransform = "uppercase";
																	break;
																case "divider":
																	break;
																case "route":
																	break;
																default:
																	break;
															}

															return (
																<DataTableBodyCell
																	key={index}
																	noBorder={noEndBorder && rows.length - 1 === key}
																	align={cell.column.align ? cell.column.align : "left"}
																	{...cell.getCellProps()}
																	style={style}
																	subTitle={
																		cell.column.id === "name"
																			? "(" + i18n.t(`SETTINGS.PAGES.${cell.row.values.type}`) + ")"
																			: null
																	}
																	override={
																		cell.column.id === "icon" ? (
																			<Icon fontSize="medium">{cell.value}</Icon>
																		) : null
																	}
																>
																	{cell.render("Cell")}
																</DataTableBodyCell>
															);
														}

														return null;
													})}
													{/* <DataTableBodyCell>
														<IconButton
															onClick={() => {
																actionEditHandle(row.values);
															}}
														>
															<Icon fontSize="medium">edit</Icon>
														</IconButton>
														<IconButton
															onClick={() => {
																actionDeleteHandle(row.values);
															}}
														>
															<Icon fontSize="medium">delete</Icon>
														</IconButton>
													</DataTableBodyCell> */}
													{actions && (
														<DataTableBodyCell align="center">
															<MDBox display="flex" justifyContent="flex-end">
																{actions.map((action, index) => {
																	action = overrideObjectProps(action, row);
																	return <span key={index}>{action}</span>;
																})}
															</MDBox>
														</DataTableBodyCell>
													)}
													{hasActions && (
														<DataTableBodyCell>
															<IconButton
																onClick={event => {
																	actionEditHandle(row.values);
																	event.stopPropagation();
																	event.preventDefault();
																}}
															>
																<Icon fontSize="medium">edit</Icon>
															</IconButton>
															<IconButton
																onClick={event => {
																	actionDeleteHandle(row.values);
																	event.stopPropagation();
																	event.preventDefault();
																}}
															>
																<Icon fontSize="medium">delete</Icon>
															</IconButton>
														</DataTableBodyCell>
													)}
												</TableRow>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</TableBody>
						)}
					</Droppable>
				</Table>
			</DragDropContext>
		</TableContainer>
	);
}
export default PagesDataTable;
