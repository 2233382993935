import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useState } from "react";
import i18n from "i18n";

const DictionaryButton = ({ dictionary, handleClick, disabled, sx = { mr: 1 } }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	return (
		<MDBox>
			<DictionaryMenu
				placement="right"
				dictionary={dictionary}
				anchorEl={anchorEl}
				handleInsertText={e => {
					handleClick(e);
					setAnchorEl(null);
				}}
				handleClose={() => setAnchorEl(null)}
			/>
			<MDButton
				disabled={disabled}
				variant="gradient"
				color="info"
				onClick={e => setAnchorEl(e.target)}
				sx={sx}
			>
				{i18n.t("SETTINGS.choose")}
			</MDButton>
		</MDBox>
	);
};

export default DictionaryButton;
